<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Jadwal Tryout</div>
            </b-col>
            <b-col>
              <div align="right">
                <b-button
                  v-if="allowCreate()"
                  variant="outline-primary"
                  :to="{ name: 'admin-jadwal-tambah' }"
                  class="btn-icon"
                >
                  <!-- v-b-modal.modal-sm -->
                  <!-- @click="ModalShow()" -->
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="sm"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-form-group label="Pilih Paket" label-for="paket">
                      <b-form-select v-model="form.paket_id" :options="optpaket" />
                      <!-- @input="getDataUser_id()" -->
                      <!-- <v-select
                        class="select-size-sm w-full"
                        label="text"
                        v-model="form.paket_id"
                        @input="getDataUser_id()"
                        :clearable="true"
                        :options="optpaket"
                      /> -->
                    </b-form-group>
                    <b-form-group label="Nama Jadwal" label-for="name">
                      <b-form-input id="name" v-model="form.name" placeholder="" />
                    </b-form-group>
                    <b-form-group label="Maksimal Kuota" label-for="max_quota">
                      <b-form-input
                        type="number"
                        id="max_quota"
                        v-model="form.max_quota"
                        placeholder=""
                      />
                    </b-form-group>
                    <!-- <b-form-group label="Pilih Ujian" label-for="ujian">
                      <b-form-select v-model="form.ujian_id" :options="optujian" />
                      <v-select
                        class="select-size-sm w-full"
                        label="text"
                        v-model="form.ujian_id"
                        :clearable="true"
                        :options="optujian"
                      /> 
                    </b-form-group> -->
                    <!-- <b-form-group label="Pilih Peserta" label-for="user_id">
                      <b-form-select v-model="form.user_id" :options="optuser_id" /> -->
                    <!-- <v-select
                        class="select-size-sm w-full"
                        label="text"
                        v-model="form.user_id"
                        :clearable="true"
                        :options="optuser_id"
                      /> -->
                    <!-- @input="getDataUser_id()" -->
                    <!-- </b-form-group> -->
                    <b-form-group label="Active" label-for="aktif">
                      <b-form-group>
                        <b-form-checkbox v-model="form.active" switch inline />
                      </b-form-group>
                    </b-form-group>
                    <b-form-group
                      label="Atur Tanggal di masing-masing ujian"
                      label-for="gratis"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          v-model="form.is_date_in_subject"
                          switch
                          inline
                        />
                      </b-form-group>
                    </b-form-group>
                    <span class="" v-if="!form.is_date_in_subject">
                      <b-form-group label="Tanggal & Waktu Mulai" label-for="start_time">
                        <flat-pickr
                          v-model="form.start_time"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S',
                            time_24hr: true,
                          }"
                        />
                        <!-- <b-form-input
                        id="start_time"
                        v-model="form.start_time"
                        placeholder="start_time"
                      /> -->
                      </b-form-group>
                      <b-form-group label="Tanggal & Waktu Akhir" label-for="end_time">
                        <flat-pickr
                          v-model="form.end_time"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S',
                            time_24hr: true,
                          }"
                        />
                        <!-- <b-form-input
                        id="end_time"
                        v-model="form.end_time"
                        placeholder="end_time"
                      /> -->
                      </b-form-group>
                    </span>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="3" class="">
              <label>Pilih Paket </label>
              <v-select
                class="select-size-sm w-full"
                label="text"
                v-model="filterPaket"
                @input="getData()"
                :clearable="true"
                :options="optpaket"
              />
            </b-col>
            <!-- <b-col md="3" sm="3" class="">
              <label>Pilih Ujian</label>
              <v-select
                label="value"
                v-model="filterUjian"
                class="select-size-sm w-full"
                @input="getData()"
                :clearable="true"
                :options="optujian"
              />
            </b-col> -->
          </b-row>
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <!-- <b-form-group label="Sort" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-50">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-30">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group> -->
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12" v-if="filterPaket">
              <b-table
                sticky-header="true"
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="jadwaladmindata"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <div v-if="jadwaladmindata.length == 1">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                          style="height: 100px"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{ path: `jadwal-admin/detail/${row.item.id}` }"
                      >
                        <feather-icon icon="SettingsIcon" class="mr-50" />
                        <span>Detail</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{ path: `jadwal-admin/report/${row.item.id}` }"
                      >
                        <feather-icon icon="BarChartIcon" class="mr-50" />
                        <span>Report</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="allowUpdate()"
                        :to="{ path: `jadwal-admin/edit/${row.item.id}` }"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="allowDelete()" @click="ModalHapus(row.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-if="jadwaladmindata.length != 1">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                        <!-- // style="height: 100px" -->
                      </template>
                      <b-dropdown-item
                        :to="{ path: `jadwal-admin/detail/${row.item.id}` }"
                      >
                        <feather-icon icon="SettingsIcon" class="mr-50" />
                        <span>Detail</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{ path: `jadwal-admin/report/${row.item.id}` }"
                      >
                        <feather-icon icon="BarChartIcon" class="mr-50" />
                        <span>Report</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="allowUpdate()"
                        :to="{ path: `jadwal-admin/edit/${row.item.id}` }"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="allowDelete()" @click="ModalHapus(row.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <!-- <b-button variant="outline-info" class="btn-icon" @click="ModalUbah(row.item)"> <feather-icon icon="EditIcon" /></b-button>
                  <b-button variant="outline-danger" class="btn-icon ml-1" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button> -->
                </template>
                <!-- A custom formatted column -->
                <template #cell(name)="data">
                  {{ data.item.name }}
                  <br />
                  <b-badge variant="warning" v-if="data.item.is_date_in_subject == 1"
                    >Tanggal awal dan akhir di masing-masing ujian
                  </b-badge>
                  <b-badge variant="primary" v-else>
                    {{ humanDateTime(data.item.start_time) }} -
                    {{ humanDateTime(data.item.end_time) }}
                  </b-badge>
                </template>
                <template #cell(active)="data">
                  <b-badge v-if="data.item.active == 1" variant="light-success">
                    AKTIF
                  </b-badge>
                  <b-badge v-if="data.item.active == 0" variant="light-danger">
                    NONAKTIF
                  </b-badge>
                </template>
                <template #cell(file_pembahasan)="data">
                  <b-badge v-if="data.item.file_pembahasan == 1" variant="light-success">
                    BUKA
                  </b-badge>
                  <b-badge v-if="data.item.file_pembahasan == 0" variant="light-danger">
                    TUTUP
                  </b-badge>
                </template>
                <template #cell(start_time)="data">
                  {{ humanDateTime(data.item.start_time) }}
                </template>
                <template #cell(end_time)="data">
                  {{ humanDateTime(data.item.end_time) }}
                </template>
                <template #cell(timer)="data">
                  {{ data.item.timer + " menit" }}
                </template>

                <!-- A virtual composite column -->
                <template #cell(peserta)="data">
                  {{
                    data.item.max_quota == 0
                      ? data.item.sum_member + "/&infin;"
                      : data.item.sum_member + "/" + data.item.max_quota
                  }}
                  <!-- {{ data.item.sum_member + "/" + data.item.max_quota }} -->
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
            <b-col cols="12" v-else>
              <b-card class="border text-center text-primary">
                Silahkan pilih paket terlebih dahulu!!
              </b-card>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    flatPickr,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      filterPaket: null,
      filterUjian: null,
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      optujian: [],
      optpaket: [],
      optuser_id: [],
      jadwaladmindata: [],
      form: {
        paket_id: null,
        name: null,
        is_date_in_subject: 0, // nilai: 1 atau 0 || jika 1 artinya start_time endtime akan diset di ujian masing-masing, 0 maka start_time endtime akan disamakan untuk ujian2 di jadwal tsb
        start_time: "", // MANDATORY jika is_date_in_subject = 0
        end_time: "", // MANDATORY jika is_date_in_subject = 0
        active: 1, // OPSIONAL
        max_quota: 0, // MANDATORY
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "paket.name", label: "Paket", sortable: true },
        { key: "name", label: "Nama Jadwal" },
        { key: "peserta", label: "Peserta (Total/Kuota)" },
        // { key: "start_time", label: "Tanggal & Waktu Mulai" },
        // { key: "end_time", label: "Tanggal & Waktu Akhir" },
        { key: "active", label: "status" },
        { key: "file_pembahasan", label: "Pembahasan" },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optWarna: [
        { value: "primary", text: "primary" },
        { value: "info", text: "info" },
        { value: "secondary", text: "secondary" },
        { value: "warning", text: "warning" },
        { value: "success", text: "success" },
        { value: "light-primary", text: "light-primary" },
        { value: "light-info", text: "light-info" },
        { value: "light-secondary", text: "light-secondary" },
        { value: "light-warning", text: "light-warning" },
        { value: "light-success", text: "light-success" },
      ],
      optFilter: [
        { id: "name", value: "Kategori" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {
    "form.is_date_in_subject"(val) {
      if (val == true) {
        this.start_time = "";
        this.end_time = "";
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        type_ujian: null,
        name: null,
        shortname: null,
        soal_ids: null,
        start_time: "",
        end_time: "",
        timer: null,
        min_nilai: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Jadwal";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      // this.getDataUserEdit(item);
      this.form = item;
      this.form.is_date_in_subject = item.is_date_in_subject == 1 ? true : false;
      this.form.active = item.active == 1 ? true : false;
      if (
        this.form.start_time == "0000-00-00 00:00:00" ||
        this.form.end_time == "0000-00-00 00:00:00"
      ) {
        this.form.start_time = "";
        this.form.end_time = "";
      }
      // this.form.user_id = item.user_id;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Jadwal";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: data.id,
            fungsi: 2,
          };
          this.$store
            .dispatch("jadwaladmin/save", payload)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.paket_id == null || this.form.paket_id == "") {
        this.pesanGagal();
        return false;
      }

      var payload = {
        paket_id: this.form.paket_id,
        name: this.form.name,
        max_quota: this.form.max_quota,
        is_date_in_subject: this.form.is_date_in_subject == true ? 1 : 0,
        active: this.form.active == true ? 1 : 0,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("jadwaladmin/save", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Jadwal berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        ujian_id: this.filterUjian != null ? this.filterUjian.id : null,
      };
      this.$store
        .dispatch("jadwaladmin/index", payload)
        .then((response) => {
          this.loading = false;
          let jadwaladmindata = response.data.data;
          this.jadwaladmindata = jadwaladmindata;
          this.totalRows = this.jadwaladmindata.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataPaket() {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("paketTryout/index", payload)
        .then((response) => {
          // this.loading = false;
          let optpaket = response.data.data;
          optpaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket = optpaket;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataUser_id() {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        paket_id: this.form.paket_id != null ? this.form.paket_id : null,
      };
      this.$store
        .dispatch("paketTryout/indexMemberPaket", payload)
        .then((response) => {
          // this.loading = false;
          let optuser_id = response.data.data;
          optuser_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optuser_id = optuser_id;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataUserEdit(item) {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        paket_id: item.paket_id != null ? item.paket_id : null,
      };
      this.$store
        .dispatch("paketTryout/indexMemberPaket", payload)
        .then((response) => {
          // this.loading = false;
          let optuser_id = response.data.data;
          optuser_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optuser_id = optuser_id;
          this.form.user_id = item.user_id;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },

    async getDataUjian() {
      // this.loading = true;
      const payload = {
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          // this.loading = false;
          let optujian = response.data.data;
          optujian.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optujian = optujian;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  async mounted() {
    // this.getData();
    this.getDataPaket();
    // this.getDataUjian();
    // this.getDataUser_id();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
